import { SEO } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Box } from 'theme-ui';

function StayingSafe() {
  const { post } = useStaticQuery(graphql`
    query StayingSafe {
      post: markdownRemark(frontmatter: { slug: { eq: "staying-safe" } }) {
        id
        htmlAst
        html
      }
    }
  `);
  return (
    <>
      <SEO title="Staying Safe" />
      <Box
        sx={{
          boxSizing: 'content-box',
          py: ['l', null, 'xl'],
          px: 'm',
          maxWidth: 'measure',
          mx: 'auto',
          '& ul': {
            pl: ['m', null, 0],
          },
        }}
      >
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
      </Box>
    </>
  );
}

export default StayingSafe;
